body {
  display: grid;
  gap: var(--gap);
  grid-template-rows: auto 1fr auto;
  scrollbar-gutter: stable;
  height: 100%;

  & > main {
    @include grid;

    gap: 0;
    align-content: flex-start;

    & > * {
      grid-column: content;
    }

    // Blocks
    & .block {
      // image grid streafield block
      &-image_grid_block {
        display: grid;
        gap: calc(var(--gap) * 0.5);
        grid-template-columns: repeat(2, 1fr);

        figure {
          margin-bottom: 0;
        }
      }

      // two column block
      &-two_column_block {
        grid-column: feature;
        display: grid;
        gap: var(--gap);

        @include gridAutoFit;

        --col: 20rem;

        // grid-template-columns: repeat(2, 1fr);

        aside {
          // vertical alignment of text content
          &.block-paragraph {
            &.vertical {
              &-start {
                align-self: start;
              }

              &-center {
                align-self: center;
              }

              &-end {
                align-self: end;
              }
            }

            p:last-child {
              margin-bottom: 0;
            }
          }
        }

        figure {
          margin-bottom: 0;
        }
      }
    }
  }

  // Header & Footer full-width
  & > header,
  & > footer {
    @include grid;

    padding: var(--gap);
    gap: 0;

    & > * {
      grid-column: full;
      gap: 0.3rem;
    }
  }
}

// template-specific
.template {
  &-workpage--index {
    & > main {
      & > * {
        grid-column: full;
      }

      & > header {
        grid-column: feature;
      }
    }
  }

  &-workpage {
    .related-content {
      grid-column: feature;
      margin-top: var(--gap);

      &__list {
        @include grid;
        @include gridAutoFit;
      }

      article {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        header {
          justify-self: center;
          order: 2;
        }

        main {
          order: 1;

          figure {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &-home {
    // UpCycling video on home
    &.theme-up {
      .block-media_block {
        grid-column: feature;
      }
    }

    // Dexe videos
    &.theme-dexe {
      main {
        [class^="block"] {
          margin-bottom: 0;
        }

        .block-two_column_block {
          grid-column: full;
          gap: 2rem;
        }

        .block-wrapper {
          @include flex;
          @include flexColumn;
          justify-content: space-between;
        }

        @include media-breakpoint-up(md) {
          .block-two_column_block {
            grid-template-columns: 3fr 2fr;
          }
        }
      }
    }
  }
}

// Featured projects on home page, Projects overview
// Full-width + title rotated on the left on sm+
.work-listing {
  @extend .layout-featured;

  display: grid;
  gap: var(--gap);

  .template-home &,
  .template-workpage--index & {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gap);
  }

  .template--gallery & {
    // size of the grid entity
    --grid-cell: 80px;

    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;

    & > article {
      // --a-c: article column
      // --a-c-s: article column span
      // --a-r: article row
      // --a-r-s: article row span

      grid-column: var(--a-c) / span var(--a-c-s);
      grid-row: var(--a-r) / span var(--a-r-s);

    }
  }

  article {
    position: relative;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: calc(var(--gap) * 0.2);
    }

    header {
      order: 2;

      @include media-breakpoint-up(sm) {
        order: unset;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        align-self: flex-start;
      }

      & > * {
        margin: 0;
      }

      h2 > span {
        color: var(--foreground-lower);
      }
    }

    figure {
      margin: 0;
    }
  }
}

// Left padding in 1/3, 1/4 & 1/5 of width
@include media-breakpoint-up(md) {
  .layout {
    &-indented {
      padding-inline-start: var(--indent);
    }

    &-third {
      --indent: 20vw;
    }

    &-fourth {
      --indent: 15vw;
    }

    &-fifth {
      --indent: 10vw;
    }
  }
}

// margins on blocks
[class^="block"] {
  margin-bottom: calc(var(--gap) * 0.5);
}
