html {
  height: 100%;

  &:focus-within {
    scroll-behavior: smooth;
  }
}

body {
  height: 100%;
  color: var(--foreground);
  background: var(--background);
}

a {
  color: var(--link);
  text-decoration: none;

  p &,
  ul &,
  li & {
    text-decoration: underline solid var(--accent) 1px;
    text-underline-offset: 0.2rem;
  }

  &:hover {
    color: var(--link-higher);
  }
}

img,
video {
  @include img-fluid;
}

figure {
  figcaption {
    color: var(--middleground);
    font-family: GeometriaItalic, var(--bs-font-sans-serif);
    font-style: normal;
  }
}

body > svg {
  display: none; // Hide SVG sprite put directly in body
}

// pagination
.pagination {
  .page-item.disabled .page-link {
    color: var(--middleground);
    background-color: var(--background);
  }

  .page-link {
    color: var(--link);
    background-color: var(--background-higher);
  }

  .page-item.active .page-link {
    border-color: var(--link);
    background-color: var(--link);
  }
}

// pills
.nav-pills {
  .nav-link.disabled {
    color: var(--middleground);
  }

  .nav-link {
    color: var(--link);
    background-color: transparent;
  }

  .nav-link.active {
    outline: 1px solid var(--link);
    color: var(--link);
    background-color: transparent;
  }
}

// content
.title {
  &-block__ {
    &tags {
      margin-bottom: var(--gap);
    }
  }
}

// document block
.block {
  &-document_block {
    margin-bottom: var(--gap);

    &:last-child {
      margin-bottom: calc(var(--gap) * 2);
    }
  }

  // page block
  &-page_block {
    display: block;
    float: left;
    margin: 0.3rem calc(var(--gap) * 1.5) 0.3rem 0;
    padding: var(--gap);
    border: 2px solid var(--border-color);
    max-width: 35vw;
  }
}

blockquote {
  margin: var(--gap) 0.7rem;
  position: relative;
  padding-left: 4rem;

  &::before {
    content: "\201C"; /* Unicode for Left Double Quote */
    color: var(--accent);
    font-family: Georgia, Palatino, "Times New Roman", Times, serif;
    font-size: clamp(8rem, 4vw, 10rem);
    position: absolute;
    left: 0;
    top: -4rem;

    p {
      display: inline;
    }
  }
}

.btn-link {
  color: currentcolor;
}

// Footer
body > footer div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: var(--gap);
}

// Tags
.tag {
  background-color: var(--link);
  color: var(--background);
  text-decoration: none;
  margin-bottom: 0.2rem;

  &:hover {
    color: var(--background-higher);
    background-color: var(--link-higher);
  }

  &--active {
    background-color: var(--link-higher);
    color: var(--background);

    &:hover {
      color: var(--background-higher);
    }
  }
}

// intersection observer induced animation for image blocks
@media (prefers-reduced-motion: no-preference) {
  .js {
    .block-image_block {
      transform: translateY(7vmax);
      will-change: transform, opacity;

      figure {
        opacity: 0.6;
      }
    }

    .is--in-view {
      transform: translateY(0);
      transition: all 700ms ease;
      transition-property: transform;
      transition-delay: 0;

      figure {
        opacity: 1;
      }
    }
  }
}
