// navbar
.navbar {
  flex-wrap: nowrap;

  &-nav {
    .nav-link {
      color: var(--foreground);
      font-size: var(--font-size-fluid-1);

      &:hover {
        color: var(--foreground-higher);
      }
    }

    .nav-item {
      &.is_active > .nav-link {
        font-weight: 700;
        color: var(--foreground-higher);

        // border-bottom: 1px solid var(--link);
      }

      .offcanvas.show & {
        margin-left: -0.5rem;
        padding-left: 0.5rem;
      }
    }
  }

  &-toggler {
    border-color: transparent;

    &:focus,
    &:active {
      outline: 0;
      box-shadow: none;
    }
  }

  &-brand {
    margin-bottom: 0;
    padding: 0;
    display: inline-block;

    a {
      --link-higher: var(--link);

      display: flex;
      gap: 1rem;
      flex-flow: column wrap;
      align-items: center;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }

      span {
        display: block;
        text-indent: -1000%;
        background-size: contain;
        overflow: hidden;
        font-family: GeometriaBold, var(--bs-font-sans-serif);

        &.site-name {
          aspect-ratio: 1;
          max-width: 4rem;
          background: transparent url($logo) 0 50% no-repeat;

          // default: light / no pref
          @media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
            background-image: url($logo);
          }

          [data-theme="light"] & {
            background-image: url($logo);
          }

          // dark
          @media (prefers-color-scheme: dark) {
            background-image: url($logo-neg);
          }

          [data-theme="dark"] & {
            background-image: url($logo-neg);
          }
        }
      }
    }
  }
}

[data-theme="light"] {
  .navbar-custom {
    @extend .navbar-light;
  }
}

[data-theme="dark"] {
  .navbar-custom {
    @extend .navbar-dark;
  }
}

.offcanvas {
  &-header {
    display: flex;
    justify-content: space-between;
  }
}
