@font-face {
  font-family: Geometria;
  src: url("../font/GeometriaRegular/font.woff2") format("woff2"), url("../font/GeometriaRegular/font.woff") format("woff");
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeometriaBold;
  src: url("../font/GeometriaBold/font.woff2") format("woff2"), url("../font/GeometriaBold/font.woff") format("woff");
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeometriaBoldItalic;
  src: url("../font/GeometriaBoldItalic/font.woff2") format("woff2"), url("../font/GeometriaBoldItalic/font.woff") format("woff");
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeometriaItalic;
  src: url("../font/GeometriaItalic/font.woff2") format("woff2"), url("../font/GeometriaItalic/font.woff") format("woff");
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

body {
  font-family: Geometria, var(--bs-font-sans-serif);
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizelegibility;
  font-weight: normal;
  font-optical-sizing: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

:root {
  --font-size-fluid-0x: clamp(1rem, 1.5vw, 1.2rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: GeometriaBoldItalic, var(--bs-font-sans-serif);
  font-weight: bold;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6, blockquote {
  text-wrap: balance;
}

h1 {
  font-size: var(--font-size-fluid-3);

  &.title-block__heading {
    line-height: 1;
  }
}

h2 {
  font-size: var(--font-size-fluid-2);

  .block--heading_block & {
    line-height: 1;
  }
}

h3 {
  font-size: var(--font-size-fluid-1);
}

h4 {
  font-size: var(--font-size-fluid-0x);
}

// h5 {
//   font-size: var(--font-size-fluid-0);
// }

p {
  font-size: var(--font-size-fluid-0x);
  hyphens: auto;
}

nav,
b,
strong {
  font-family: GeometriaBold, var(--bs-font-sans-serif);
}

blockquote {
  font-family: GeometriaItalic, var(--bs-font-sans-serif);
  font-style: normal;
}
