// Repo: https://github.com/argyleink/gui-challenges/tree/main/theme-switch
// Article: https://web.dev/building-a-theme-switch-component/
// Demo: https://gui-challenges.web.app/theme-switch/dist/

.sun-and-moon {
  & > :is(.moon, .sun, .sun-beams) {
    transform-origin: center center;
  }

  & > :is(.moon, .sun) {
    fill: var(--accent);

    @at-root .theme-toggle:is(:hover, :focus-visible) > & {
      fill: var(--foreground-higher);
    }
  }

  & > .sun-beams {
    stroke: var(--accent);
    stroke-width: 2px;

    @at-root .theme-toggle:is(:hover, :focus-visible) & {
      stroke: var(--foreground-higher);
    }
  }

  @at-root [data-theme="dark"] & {
    & > .sun {
      transform: scale(1.75);
    }

    & > .sun-beams {
      opacity: 0;
    }

    & > .moon > circle {
      transform: translateX(-7px);

      @supports (cx: 1) {
        transform: translateX(0);
        cx: 17;
      }
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    & > .sun {
      transition: transform 0.5s var(--ease-elastic-3);
    }

    & > .sun-beams {
      transition:
        transform 0.5s var(--ease-elastic-4),
        opacity 0.5s var(--ease-3);
    }

    & .moon > circle {
      transition: transform 0.25s var(--ease-out-5);

      @supports (cx: 1) {
        transition: cx 0.25s var(--ease-out-5);
      }
    }

    @at-root [data-theme="dark"] & {
      & > .sun {
        transform: scale(1.75);
        transition-timing-function: var(--ease-3);
        transition-duration: 0.25s;
      }

      & > .sun-beams {
        transform: rotateZ(-25deg);
        transition-duration: 0.15s;
      }

      & > .moon > circle {
        transition-delay: 0.25s;
        transition-duration: 0.5s;
      }
    }
  }
}
